.payt-block-destaque-outer-wrapper {
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 3px 20px #00000010;
  cursor: pointer;
}

.payt-block-destaque-outer-wrapper:hover {
  box-shadow: 0px 23px 40px #00000029;
}

.payt-block-destaque-outer-wrapper:hover img {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.payt-block-destaque-img-container {
  width: 45%;
  height: 300px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 20px;
}

.payt-block-destaque-img-container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.payt-block-destaque-info-container {
  width: calc(55% - 20px);
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.payt-block-destaque-badges-container {
  width: 100%;
}

.payt-block-destaque-nextdate-container {
  margin-left: 80px;
}

.payt-block-destaque-nextdate-container > .label {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 11px;
}

.payt-block-destaque-nextdate-container > .date {
  background-color: white;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2px;
  padding-bottom: 0px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  top: -5px;
}

.payt-block-destaque-badge {
  display: inline;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 11px;
  text-align: center;
  font-weight: 400;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
}

.payt-block-destaque-titulo-atividade-container {
  width: 90%;
  color: #464646;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  white-space: initial;
  margin-bottom: 10px;
  margin-top: 10px;
}

.payt-block-destaque-preco-container {
  height: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.payt-block-destaque-preco-container > small {
  font-size: 11px;
}

.payt-block-destaque-preco-container > strong {
  font-size: 18px;
}

.payt-block-destaque-preco-container.high {
  height: 70px;
}

.payt-block-destaque-itens-container {
  width: 80%;
  max-height: 45%;
  min-height: 30%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.payt-block-destaque-caracteristica {
  color: #4B4B4B;
  overflow: hidden;
  padding-right: 5px;
  font-size: 12px;
  margin-bottom: 10px;
  width: 32%;
}

.payt-block-destaque-caracteristica > span {
  vertical-align: sub;
}